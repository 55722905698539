import CollapseEnum from '../../enumerators/single/collapse.enum'
// eslint-disable-next-line import/no-unresolved
import LinkAnchorEnum from '../../enumerators/single/link-anchor.enum'

export default class LinkAnchorAction {
    constructor() {
        if (window.matchMedia('(max-width: 1000px)').matches) {
            LinkAnchorAction.clickAnchor()
        }
    }

    static clickAnchor() {
        if ($(LinkAnchorEnum.LINK_TRIGGER).length > 0) {
            $(document).on('click', LinkAnchorEnum.LINK_TRIGGER, function (e) {
                e.preventDefault()
                const target = $(this).attr('href')
                const parent = $(target).parents(LinkAnchorEnum.SECTION_ELEMENT)
                if (parent.length > 0) {
                    parent.addClass(CollapseEnum.COLLAPSE_TRIGGER_OPEN_CLASS)
                    $(window).scrollTop(parent.offset().top)
                } else if ($(target).length > 0) {
                    $(window).scrollTop($(target).offset().top)
                }
            })
        }
    }
}
