import CommentsEnum from '../enumerators/comments.enum'

export default class CommentsService {
    constructor() {
        //Comments
        if ($(CommentsEnum.COMMENTS).length > 0) {
            import(
                '../actions/comments.action' /* webpackChunkName: "scripts/comments.action" */
            ).then(({ default: CommentsAction }) => {
                new CommentsAction()
            })
        }
    }
}
