import MapFullscreeenEnum from '../../enumerators/single/map-fullscreen.enum'

export default class MapFullscreenService {
    constructor() {
        if ($(MapFullscreeenEnum.MAP_FULLSCREEN_TRIGGER).length > 0) {
            import(
                '../../actions/single/map-fullscreen.action' /* webpackChunkName: "scripts/map-fullscreen.action" */
            ).then(({ default: MapFullscreen }) => {
                MapFullscreen.toggleMap()
            })
        }
    }
}
