import CollapseEnum from '../../enumerators/single/collapse.enum'

export default class CollapseService {
    constructor() {
        if ($(CollapseEnum.COLLAPSE_ELEMENT).length > 0) {
            import(
                '../../actions/single/collapse.action' /* webpackChunkName: "scripts/collapse.action" */
            ).then(({ default: CollapseAction }) => {
                new CollapseAction()
            })
        }
    }
}
