import CrossSellingEnum from '../../enumerators/single/cross-selling.enum'

export default class CrossSellingService {
    constructor() {
        if ($(CrossSellingEnum.BLOCK).length > 0) {
            import(
                '../../components/single/cross-selling.component' /* webpackChunkName: "scripts/cross-selling.component" */
            ).then(({ default: CrossSellingComponent }) => {
                new CrossSellingComponent()
            })
        }
    }
}
