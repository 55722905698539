export default class CommentsEnum {
    static COMMENTS = '[data-element="comments"]'
    static LOADER = '[data-element="loader"]'
    static PAGINATION = '[data-element="comments-pagination"]'
    static PAGINATION_NUMBER = '[data-element="comments-pagination-number"]'
    static WRAPPER = '[data-element="comments-wrapper"]'
    static PREV = '[data-element="comments-pagination-prev"]'
    static NEXT = '[data-element="comments-pagination-next"]'
    static CURRENT_POST = $(CommentsEnum.COMMENTS).attr('data-post-id')
}
