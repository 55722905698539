import TagsEnum from '../../enumerators/single/tags.enum'

export default class TagsService {
    constructor() {
        if ($(TagsEnum.TAGS_TRIGGER).length > 0) {
            import(
                '../../actions/single/tags.action' /* webpackChunkName: "scripts/tags.action" */
            ).then(({ default: TagsAction }) => {
                TagsAction.show()
            })
        }
    }
}
